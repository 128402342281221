<template>
    <div>
        <div class="mt-3 ml-1 vx-row">
            <vs-button class="mr-2" color="primary" icon-pack="feather" icon="icon-plus" @click="$refs.file.click()">New Import</vs-button>
            <input type="file" ref="file" accept=".xls,.xlsx" @change="handleImport" class="d-none" :key="fileKey" />
            <vs-button v-if="this.type == 'import-po-return-begbal' || this.type == 'import-gr-return-begbal'" class="mr-2" color="primary" type="border" icon-pack="feather" icon="icon-download" @click="handleDownloadTemplate()">Template Download</vs-button>
        </div>
        <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Log Status</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect :options="this.statusOptions" label="name" track-by="name" @selected="(val) => {
                        this.status = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :type="this.type" :draw="draw" :status="this.status? this.status.name:''" :date="this.date">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import CustomMSelect from "./components/CustomMultiSelect.vue";
import Date from "./components/Date.vue";
import Table from "./components/LogImportTable.vue";
export default {
    components: {
        CustomMSelect,
        Date,
        Table
    },
    props: {
        type: {
            type: String,
            default: "import-po",
        },
    },
    data() {
        return {
            activeTab: "LogImport",
            fileKey: 0,
            draw: 0,
            date: null,
            status: null,
            statusOptions: [
                {
                    name: "Pending",
                },
                {
                    name: "Imported",
                },
                {
                    name: "Failed",
                }
            ],
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
        },
        handleImport(e){
            this.$vs.dialog({
                type: "confirm",
                title: `Import from ${e.target.files[0].name}`,
                text: `This action CANNOT be undone, CANCEL to change the file or ACCEPT to continue`,
                accept: () => {
                    this.$vs.loading();
                    var formData = new FormData();
                    formData.append("file", e.target.files[0])
                    formData.append("type", this.type)
                    let url = `${this.$store.state.purchaseOrder.baseUrlPath}/import-begbal`

                    if (this.type == "import-gr-begbal") {
                        url = `${this.$store.state.inbound.purchaseOrder.baseUrlPath}/receive/import`
                    }

                    if (this.type == "import-po-return-begbal") {
                        url = "/api/wms/v1/wms/purchase/po-return/import-file-po-return"
                    }

                    if (this.type == "import-gr-return-begbal") {
                        url = "/api/wms/v1/wms/purchase/po-return/import-file-gr-return"
                    }

                    this.$http.post(url, formData)
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.dialog({
                                type: "alert",
                                color: "success",
                                title: `Success`,
                                text: resp.message
                            });
                            this.handleFilter()
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                type: "alert",
                                color: "danger",
                                title: `Unauthorized`,
                                text: resp.message,
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "alert",
                            color: "danger",
                            title: `Error`,
                            text: error,
                        });
                    });
                },
            });
            this.fileKey++
        },
        handleDownloadTemplate() {
            let url = ""
            var fileTitle = "";
            if (this.type == "import-po-return-begbal") {
                url = "/api/wms/v1/wms/purchase/po-return/export-template-po-return"
                fileTitle = "IMPORT_PO_RETURN_TEMPLATE"
            } else {
                url = "/api/wms/v1/wms/purchase/po-return/export-template-gr-return"
                fileTitle = "IMPORT_GR_RETURN_TEMPLATE"
            }

            this.$vs.loading();
            this.$http.get(url, {
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/octet-stream",
                },
            })
            .then(resp => {
                if (resp.status == "error") {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        type: "alert",
                        color: "danger",
                        title: `Unauthorized`,
                        text: resp.message,
                    });
                } else {
                    this.$vs.loading.close();
                    var fileURL = window.URL.createObjectURL(new Blob([resp]));
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;

                    fileLink.setAttribute(
                        "download",
                        fileTitle.toUpperCase() + ".xlsx"
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                this.$vs.dialog({
                    type: "alert",
                    color: "danger",
                    title: `Error`,
                    text: error,
                });
            });
        },
    }
}
</script>

<style>
.d-none{
    display: none;
    visibility: hidden;
}
</style>